
import {Component, Prop, Ref, Vue, Watch} from 'vue-property-decorator';
import Register from '@/components/card/Register.vue'
import Login from '@/components/card/Login.vue'
import IntelliaxService from "@/assets/js/services/IntelliaxService";

@Component({components: {Register, Login}})

export default class ValidateEmail extends Vue {

    private imageUrl: string = '';
    private errorMessage: string = "";
    private successMessage: string = "";
    private loading: boolean = false;

    private error: string = '';

    private emailKey: string = '';
    private parameter: string = '';

    private async mounted() {
        try {
            this.loading = true;
            this.emailKey = this.$route.query?.h?.toString();
            this.parameter = atob(this.$route.params.urlparam);
            const serviceUrl = JSON.parse(this.parameter).surl

            if (!this.parameter || !serviceUrl || !this.emailKey) {
                //console.log("!this.parameter || !serviceUrl || !this.emailKey");
                this.error = "Es ist zu einem Fehler gekommen. Bitte wenden Sie sich an den Support.";
            } else {
                try {
                    this.customizeMessages(serviceUrl);
                    let imageUrl = await IntelliaxService.registrierungAbschließen(serviceUrl, location.href);
                    if (imageUrl) {
                        if (imageUrl.toLowerCase().startsWith("https://intelliax/")) {
                            imageUrl = imageUrl.replace("https://intelliax/", serviceUrl);

                        }
                        this.$store.commit("Base/setActiveCustomerCardTab", 'login');
                        this.$store.commit("Base/setQrCodeImage", imageUrl);
                        this.imageUrl = imageUrl;
                    }

                } catch (e: any) {
                    // console.log(e);
                    this.error = "Es ist zu einem Fehler gekommen. Bitte wenden Sie sich an den Support.";
                }
            }
        } catch (e: any) {
            this.error = e;
        } finally {
            this.loading = false;
        }

    }

    private customizeMessages(serviceUrl: string): any {
        return IntelliaxService.getCustomizedInfo(serviceUrl).then((result) => {
            console.log(result);
            this.$emit("setCustomizedBase", {
                logoUrl: result?.header?.logo,
                footerCopyright: result?.footer?.text?.copyright
            });
            if (result?.darkTheme) {
                this.$vuetify.theme.dark = true;
            }
            if (result?.body?.text?.validateEmail?.success) {
                this.successMessage = result?.body?.text?.validateEmail?.success;
            } else {
                this.successMessage = "<p>Vielen Dank!<br />Ihre Email-Adresse wurde bestätigt!</p><p>Sie können sich nun mit Ihren Zugangsdaten im SB-Store anmelden.</p>";
            }
            if (result?.body?.text?.validateEmail?.error) {
                this.errorMessage = result?.body?.text?.validateEmail?.error;
            } else {
                this.errorMessage = "Leider konnte die Registrierung nicht abgeschlossen werden.";
            }

        });
    }
}
