import axios from "axios";

export default class IntelliaxService {

    public static async requiredCustomerFields(serviceUrl: string): Promise<any> {
        //Intelliax/RequiredCustomerFields
        let response = await axios.post(`${serviceUrl}/Intelliax/RequiredCustomerFields`, {SID: ''});
        if (response.data.RequiredCustomerFields_response) {
            if (response.data.RequiredCustomerFields_response.ServiceErrors) {
                throw new Error(response.data.RequiredCustomerFields_response.ServiceErrors[0].ERRORRECOMMENDATION || response.data.RequiredCustomerFields_response.ServiceErrors[0].ERRORDESCRIPTION);
            } else {
                return response.data.RequiredCustomerFields_response.CustomerFields;
            }
        }
    }

    public static async zutrittskarteBeantragen(serviceUrl: string, request: {
        RegistrationUrl: string,
        email: string,
        Passwort: string,
        Anrede: string,
        Vorname: string,
        Nachname: string,
        Geburtsdatum: string,
        Telefon: string,
        Strasse: string,
        Postleitzahl: string,
        Ort: string
    }): Promise<any> {
        //Intelliax/ZutrittskarteBeantragen
        let response = await axios.post(`${serviceUrl}/Intelliax/ZutrittskarteBeantragen`, request, {timeout: 40000});
        if (response.data.ZutrittskarteBeantragen_response) {
            if (response.data.ZutrittskarteBeantragen_response.ServiceErrors) {
                throw new Error(response.data.ZutrittskarteBeantragen_response.ServiceErrors[0].ERRORRECOMMENDATION || response.data.ZutrittskarteBeantragen_response.ServiceErrors[0].ERRORDESCRIPTION);
            } else {
                return response.data.ZutrittskarteBeantragen_response.ImageURL;
            }
        }
    }

    public static async registrierungAbschließen(serviceUrl: string, registrationLink: string): Promise<any> {
        //Intelliax/RegistrierungAbschliessen
        let response = await axios.post(`${serviceUrl}/Intelliax/RegistrierungAbschliessen`, {Bestaetigungslink: registrationLink}, {timeout: 40000});
        if (response.data.RegistrierungAbschliessen_response) {
            if (response.data.RegistrierungAbschliessen_response.ServiceErrors) {
                throw new Error(response.data.RegistrierungAbschliessen_response.ServiceErrors[0].ERRORRECOMMENDATION || response.data.RegistrierungAbschliessen_response.ServiceErrors[0].ERRORDESCRIPTION);
            }
            else {
                return response.data.RegistrierungAbschliessen_response.ImageURL;
            }
        }
    }

    public static async get_URL_Zutrittscode(serviceUrl: string, email: string, password: string): Promise<string> {
        let response = await axios.post(`${serviceUrl}/Intelliax/get_URL_Zutrittscode`, {
            Email: email,
            Passwort: password
        }, {timeout: 40000});
        if (response.data.get_URL_Zutrittscode_response) {
            if (response.data.get_URL_Zutrittscode_response.ServiceErrors) {
                throw new Error(response.data.get_URL_Zutrittscode_response.ServiceErrors[0].ERRORRECOMMENDATION || response.data.get_URL_Zutrittscode_response.ServiceErrors[0].ERRORDESCRIPTION);
            } else {
                return response.data.get_URL_Zutrittscode_response.ImageURL;
            }
        }
        return '';
    }

    private static customizedInfo:any = undefined;
    public static async getCustomizedInfo(serviceUrl: string): Promise<any> {
        if(this.customizedInfo) {
            return this.customizedInfo;
        }
        try {
            if(!serviceUrl.endsWith("/")) {
                serviceUrl+="/";
            }
            const response = await axios.get(`${serviceUrl}Intelliax/customized.json`);
            console.log(response.data);
            this.customizedInfo = response.data;
            return response.data;
        } catch (e: any) {
        }
        return {};
    }
}
