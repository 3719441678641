import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Card from "@/views/card/Card.vue";
import CreateLink from "@/views/createLink/CreateLink.vue";
import AcceptEmail from "@/views/card/ValidateEmail.vue";

Vue.use(VueRouter);

export const routes: Array<RouteConfig> = [
    {
        path: '/card',
    },
    {
        path: '/card/:urlparam',
        component: Card,
        meta: {
            pageTitle: 'Zugang',
        }
    },
    {
        path: '/createLink',
        component: CreateLink,
        meta: {
            pageTitle: 'Link erzeugen',
        }
    },
    {
        path: '/validateEmail/:urlparam',
        component: AcceptEmail,
        meta: {
            pageTitle: 'Email bestätigen',
        }
    },
];

const otherRoutes: Array<RouteConfig> = [];

const router = new VueRouter({
    mode: 'history',
    routes: routes.concat(otherRoutes),
});


export default router;
