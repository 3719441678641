import Vue from 'vue';
import type { ActionTree, GetterTree, Module, MutationTree } from 'vuex';

/**
 * Module state
 */
interface State {
    caller:string;
    activeCustomerCardTab:string;

    qrCodeImage:string;
}

const moduleState: State = {
    caller: localStorage.getItem('Base/caller')||'',
    activeCustomerCardTab: localStorage.getItem('Base/activeCustomerCardTab')||'',
    qrCodeImage: localStorage.getItem('Base/qrCodeImage')||''
};

/**
 * Module getters
 */
const moduleGetters: GetterTree<State, any> = {
    caller(state: State) {
        return state.caller || '';
    },
    activeCustomerCardTab(state: State) {
        return state.activeCustomerCardTab || 'register';
    },
    qrCodeImage(state: State) {
        return state.qrCodeImage || '';
    },
};

/**
 * Module mutations
 */
const moduleMutations: MutationTree<State> = {
    setCaller(state: State, caller: string) {
        console.log(caller);
        state.caller = caller;
    },
    setActiveCustomerCardTab(state: State, activeCustomerCardTab: string) {
        state.activeCustomerCardTab = activeCustomerCardTab;
    },
    setQrCodeImage(state: State, qrCodeImage: string) {
        state.qrCodeImage = qrCodeImage;
    },
};

/**
 * Module actions
 */
const moduleActions: ActionTree<State, any> = {

};

/**
 * Construct Module
 */
const module: Module<State, any> = {
    namespaced: true,
    state: moduleState,
    getters: moduleGetters,
    mutations: moduleMutations,
    actions: moduleActions,
};
export default module;
