import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import de from 'vuetify/src/locale/de';
import '@fortawesome/fontawesome-free/css/all.css';
import IconComponent from "@/components/IconComponent.vue";

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: { de },
        current: 'de',
    },
    icons: {
        iconfont: 'fa',

    },
    theme: {
        themes: {
            light: {
                error: '#883422',
                success: '#377359',
                info:'#2D557D',
                primary:'#2D557D',

                Red: '#CC4C33',
                Green: '#56AD89',
                Blue: '#3F78B3',
                Yellow: '#EAB766',
                LightRed: '#EEC4BB',
                LightGreen: '#C6E3D7',
                LightBlue: '#C0D5E9',
                LightYellow: '#F4DDB5',
                DarkRed: '#883422',
                DarkGreen: '#377359',
                DarkBlue: '#2D557D',
                DarkYellow: '#966514',
            },
            dark: {
                primary:'#C0D5E9',
                success: '#C6E3D7',
                info:'#C0D5E9',
                error: '#EEC4BB',

                Red: '#CC4C33',
                Green: '#56AD89',
                Blue: '#3F78B3',
                Yellow: '#EAB766',
                LightRed: '#EEC4BB',
                LightGreen: '#C6E3D7',
                LightBlue: '#C0D5E9',
                LightYellow: '#F4DDB5',
                DarkRed: '#883422',
                DarkGreen: '#377359',
                DarkBlue: '#2D557D',
                DarkYellow: '#966514',
            },
        },
    },
});
