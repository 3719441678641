
import {Component, Prop, Ref, Vue, Watch} from 'vue-property-decorator';
import IntelliaxService from "@/assets/js/services/IntelliaxService";

@Component
export default class Login extends Vue {
    private mandatoryFields:string="";
    private loading:boolean = false;
    @Prop({required:true, default:''})
    private serviceUrl!:string;
    @Ref()
    private form:any;
    private error:string='';

    mounted(){
        this.serviceUrlChanged();
    }
    @Watch("serviceUrl")
    private serviceUrlChanged(){
        if(this.serviceUrl) {
        IntelliaxService.getCustomizedInfo(this.serviceUrl).then((result)=>{
            if(result?.body?.text?.login?.mandatoryFields) {
                this.mandatoryFields = result?.body?.text?.login?.mandatoryFields;
            }else {
                this.mandatoryFields = 'Bitte füllen Sie alle Felder aus.';
            }
        });
        }
    }
    private imageUrl:string = '';
    private resetForm() {
        this.form.reset();
    }


    private async submitForm(e:any){
        e.preventDefault();
        this.error = '';
        if(this.valid) {
            try {
                this.loading = true;
                this.imageUrl = await IntelliaxService.get_URL_Zutrittscode(this.serviceUrl,this.email, this.password)
                if(this.imageUrl.toLowerCase().startsWith("https://intelliax/")) {
                    this.imageUrl = this.imageUrl.replace("https://intelliax/", this.serviceUrl);

                }
                //console.log(this.imageUrl);
                this.$emit("ImageUrlReceived", { imageUrl: this.imageUrl});
                this.$store.commit("Base/setActiveCustomerCardTab",'login');
            }catch (e:any) {
                //console.log(e);
                this.error = e; //"Es ist zu einem Fehler gekommen. Bitte wenden Sie sich an den <a href='" + this.buildSupportMail("SBStrore - Register", location.href, JSON.stringify({serviceUrl:this.serviceUrl, email: this.email})) + "'>Support</a>.";
            }finally {
                this.loading = false;
                window.scrollTo(0,0);
            }
        }
    }

    private buildSupportMail(subject: string, url: string, objectString: string): string {
        let supportEmail = "mailto:support@aprocon.de";
        supportEmail += "?subject=" + subject + "&";
        supportEmail += "body=Url:" + url + "\r\nObject:" + objectString;
        return encodeURI(supportEmail);
    }
    private valid:boolean =  false;

    private password:string = '';
    private passwordRules =   [
        (v:any)=> {
            return !!v || 'Passwort ist erforderlich';
        },
        // (v:any) => v.length <= 10 || 'Name must be less than 10 characters',
    ];

    private email:string = '';
    private emailRules = [
        (v:any) => !!v || 'E-mail ist erforderlich',
        (v:any) => /.+@.+/.test(v) || 'Bitte eine valide Email Adresse angeben',
    ];
}
