
import {Component, Prop, Ref, Vue, Watch} from 'vue-property-decorator';
import Register from '@/components/card/Register.vue'
import Login from '@/components/card/Login.vue'
import IntelliaxService from "@/assets/js/services/IntelliaxService";

@Component({components: {Register, Login}})

export default class Card extends Vue {
    private customizedBodyTextCodeInfo='';
    private customizedBodyTextInfo='';
    private imageUrl: string = '';
    private tab: any = "register";

    private serviceUrl: string = '';

    private imageReceived(args: any): void {
        this.imageUrl = args.imageUrl;
    }

    private mounted() {
        //console.log(window.location);
        try {
            const jsonParam = atob(this.$route.params.urlparam);
            this.serviceUrl = JSON.parse(jsonParam).surl;
            this.initCustomized(this.serviceUrl);
            this.imageUrl = this.$store.getters["Base/qrCodeImage"];
            this.$store.commit("Base/setQrCodeImage","");
            this.tab = this.$store.getters["Base/activeCustomerCardTab"];
            //console.log(this.serviceUrl);
        } catch (e: any) {
            console.log("Parameter can not be parsed!");
        }
    }
    private jsonString=""
    private initCustomized(serviceUrl:string) {
        IntelliaxService.getCustomizedInfo(serviceUrl).then((result)=>{
            //this.$vuetify.theme.dark = true;
            this.$emit("setCustomizedBase",{logoUrl: result?.header?.logo, footerCopyright: result?.footer?.text?.copyright});
            if(result?.darkTheme) {
                this.$vuetify.theme.dark = true;
            }
            if(result?.body?.text?.info) {
                this.customizedBodyTextInfo = result?.body?.text?.info;
            }else {
                this.customizedBodyTextInfo = "Um Zugang zum Sb Store zu bekommen müssen sie sich mit vorhandenen Zugangsdaten <a href='#'>anmelden</a> oder sich neu <a href='#r'>registrieren</a>.";
            }
            if(result?.body?.text?.codeInfo) {
                this.customizedBodyTextCodeInfo = result?.body?.text?.codeInfo;
            }else {
                this.customizedBodyTextCodeInfo = "Schön dass du da bist, halte den Zutrittscode an das Lesegerät um die Tür zu öffnen";
            }
        });

    }
}
