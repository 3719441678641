
import {Component, Prop, Ref, Vue, Watch} from 'vue-property-decorator';
import IntelliaxService from "@/assets/js/services/IntelliaxService";

@Component
export default class Register extends Vue {
    private registerSuccessMessage:string = '';

    private registerErrorMessage:string = '';
    private dataProtectionAgreement:string = "";
    private dataProtectionAgreementAccepted:boolean=false;
    private mandatoryFields:string="";
    private registerSuccess: boolean = false;
    private registerError: boolean = false;
    private showForm: boolean = false;
    private loading: boolean = false;
    private showAnrede: boolean = false;
    private showNachname: boolean = false;
    private showVorname: boolean = false;
    private showStrasse: boolean = false;
    private showPlz: boolean = false;
    private showOrt: boolean = false;
    private showTelefon: boolean = false;
    private showGeburtsdatum: boolean = false;

    @Prop({required: true})
    private serviceUrl!: string;

    @Ref()
    private form: any;
    private error: string = '';

    private resetForm() {
        this.form.reset();
    }


    @Watch("serviceUrl")
    private serviceUrlChanged(){
        if(this.serviceUrl) {
            IntelliaxService.getCustomizedInfo(this.serviceUrl).then((result) => {
                if (result?.body?.text?.register?.mandatoryFields) {
                    this.mandatoryFields = result?.body?.text?.register?.mandatoryFields;
                } else {
                    this.mandatoryFields = 'Bitte füllen Sie alle Felder aus.';
                }

                if (result?.body?.text?.register?.dataProtectionAgreement) {
                    this.dataProtectionAgreement = result?.body?.text?.register?.dataProtectionAgreement;
                }

                if (result?.body?.text?.register?.success) {
                    this.registerSuccessMessage = result?.body?.text?.register?.success;
                }else {
                    this.registerSuccessMessage = "<p>Vielen Dank für die Registrierung!</p><p>Es wurde eine Email an Sie gesendet, die Sie bestätigen müssen.</p>";
                }
                if (result?.body?.text?.register?.error) {
                    this.registerErrorMessage = result?.body?.text?.register?.error;
                }else {
                    this.registerErrorMessage = "<p>Es ist zu einem Fehler gekommen.</p><p>Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal.</p>";
                }
            });
        }
    }
    private async submitForm(e: any) {
        e.preventDefault();
        this.error = '';
        this.registerError = false;

        if (this.valid) {
            if (this.passwordRepeat !== this.registerObject.Passwort) {
                this.error = "Die angegebenen Passworte stimmen nicht überein."
                this.registerObject.Passwort = this.passwordRepeat = '';
                this.valid = false;
                window.scrollTo(0, 0);
            } else {

                //cut after ?
                this.registerObject.RegistrationUrl = (window.location.href + "?h=" + this.hashCode(this.registerObject.email)).replace("/card/", "/validateEmail/");
                try {
                    this.loading = true;
                    let imageUrl = await IntelliaxService.zutrittskarteBeantragen(this.serviceUrl, this.registerObject);
                    if (!imageUrl) {
                        // success registration
                        this.registerSuccess = true;
                    } else {
                        // email and password is
                        if(imageUrl.toLowerCase().startsWith("https://intelliax/")) {
                            imageUrl = imageUrl.replace("https://intelliax/", this.serviceUrl);

                        }
                        this.$emit("ImageUrlReceived", { imageUrl});

                    }
                    this.registerSuccess = true;
                } catch (e: any) {
                    console.log(e);
                    this.registerError = true;
                    //this.error = "Es ist zu einem Fehler gekommen. Bitte wenden Sie sich an den <a href='" + this.buildSupportMail("SBStrore - Register", location.href, JSON.stringify(this.registerObject)) + "'>Support</a>.";
                    //console.log(e);
                }finally {
                    this.loading = false;
                    window.scrollTo(0, 0);
                }
            }
        }
    }

    private buildSupportMail(subject: string, url: string, objectString: string): string {
        let supportEmail = "mailto:support@aprocon.de";
        supportEmail += "?subject=" + subject + ";";
        supportEmail += "body=Url:" + url + "\r\nObject:" + objectString;
        return encodeURI(supportEmail);
    }

    private registerObject: {
        RegistrationUrl: string, SID: string,
        email: string,
        Passwort: string,
        Anrede: string,
        Vorname: string,
        Nachname: string,
        Geburtsdatum: string,
        Telefon: string,
        Strasse: string,
        Postleitzahl: string,
        Ort: string
    } = {
        Anrede: '',
        Nachname: '',
        Vorname: '',
        email: '',
        Passwort: '',
        Geburtsdatum: '',
        Postleitzahl: '',
        Ort: '',
        Telefon: '',
        RegistrationUrl: '',
        Strasse: '',
        SID: ''
    }

    private salutationItems = ['Herr', 'Frau'];

    private salutationRules = [(v: any) => {
        return !!v || 'Anrede ist erforderlich';
    }];


    private valid: boolean = false;

    private nameRules = [
        (v: any) => {
            return !!v || 'Name ist erforderlich';
        },
        // (v:any) => v.length <= 10 || 'Name must be less than 10 characters',
    ];

    private dataProtectionAgreementRules = [
        (v: any) => {
            return !!v || 'Datenschutzvereinbarung ist erforderlich';
        },
        // (v:any) => v.length <= 10 || 'Name must be less than 10 characters',
    ];
    private vornameRules = [
        (v: any) => {
            return !!v || 'Vorname ist erforderlich';
        },
        // (v:any) => v.length <= 10 || 'Name must be less than 10 characters',
    ];
    private passwordRepeat: string = '';
    private passwordRules = [
        (v: any) => !!v || 'Passwort ist erforderlich'

    ];
    private passwordRepeatRules = [
        (v: any) => !!v || 'Passwortwiederholung ist erforderlich'

    ];

    private emailRules = [
        (v: any) => !!v || 'E-mail ist erforderlich',
        (v: any) => /.+@.+/.test(v) || 'Bitte eine valide Email Adresse angeben',
    ];

    async mounted() {
        try {
            this.loading = true;
            this.serviceUrlChanged();
            const customerFields = await IntelliaxService.requiredCustomerFields(this.serviceUrl);
            this.showAnrede = customerFields.Anrede;
            this.showVorname = customerFields.Vorname;
            this.showNachname = customerFields.Name;
            this.showOrt = customerFields.Ort;
            this.showPlz = customerFields.PLZ;
            this.showStrasse = customerFields.Strasse;
            this.showGeburtsdatum = customerFields.Geburtstag;
            this.showTelefon = customerFields.Telefon;
            this.showForm = true;
        } catch (e: any) {
            console.log(e);
            this.error = "Es ist zu einem Fehler gekommen. Bitte wenden Sie sich an den Support.";
        } finally {
            this.loading = false;
        }

    }

    private hashCode(str: string, seed: number = 0) {
        let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
        for (let i = 0, ch; i < str.length; i++) {
            ch = str.charCodeAt(i);
            h1 = Math.imul(h1 ^ ch, 2654435761);
            h2 = Math.imul(h2 ^ ch, 1597334677);
        }
        h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
        h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
        h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
        h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);

        return 4294967296 * (2097151 & h2) + (h1 >>> 0);
    }
}
