
import {Component, Ref, Vue, Watch} from 'vue-property-decorator';
import AproconFooter from '@aprocon-software/aprocon-footer';
import AproconHeader from '@aprocon-software/aprocon-header';
import Menu from '@/components/Menu.vue';
import DesktopHeader from '@/components/DesktopHeader.vue';
import {globalEventBus} from "@/main";
import PersistentDialog from "@aprocon-software/aprocon-main-components";
import CloseDialog from "@/components/CloseDialog.vue";
import UploadButton from "@/components/general/UploadButton.vue";
@Component({
    components: {
        PersistentDialog
    },
})
export default class App extends Vue {
    private footerCopyright:string ="";
    private logoPath:string="";
    private setCustomizedBase(args:{logoUrl:string, footerCopyright:string}) {
        this.logoPath = args.logoUrl || "../img/aprocon_logo-30.png";
        this.footerCopyright = args.footerCopyright || "Copyright 2024 APRO.CON Software GmbH & Co. KG";
    }
}
