
import {Component, Prop, Ref, Vue, Watch} from 'vue-property-decorator';
import Register from '@/components/card/Register.vue'
import Login from '@/components/card/Login.vue'

@Component({ components: {Register, Login}})

export default class CreateLink extends Vue {
    private copyLink(){
        navigator.clipboard.writeText(this.generatedLink);
    }
    @Ref()
    private form:any;
    private valid:boolean =  false;
    private baseUrl : string = 'https://aprocon-customer-utility-prod.delta.k8s-wdy.de/card/';
    private serviceUrl:string = '';

    private generatedLink:string = ''
    private resetForm() {
        this.form.reset();
    }
    private submitForm(){
        if(this.valid) {
            const parameterString = btoa("{ \"surl\": \"" + this.serviceUrl+ "\" }");
            this.generatedLink = this.baseUrl + parameterString;
        }
    }

}
